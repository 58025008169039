import axios from 'axios';
let client;
export default client = axios.create({
          // baseURL:'http://13.235.92.27:3000/api/',
           baseURL:'https://emsapi.shopptrinidad.com/api/',
          // baseURL:'http://localhost:9000/api/',

          headers: {
                'Authorization': 'Bearer '+localStorage.getItem("jwtToken"),
                'Content-Type': 'application/json'
              }
  });
 