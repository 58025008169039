import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Toggle from 'react-toggle'
import "react-toggle/style.css"
// redux action
import * as actions from '../actions';
import { connect } from 'react-redux';
import { CSVLink, CSVDownload } from "react-csv";
import moment from 'moment'
import Datatable from 'react-bs-datatable';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const csvData = [
    ["firstname", "lastname", "email"],
    ["Ahmed", "Tomi", "ah@smthing.co.com"],
    ["Raed", "Labes", "rl@smthing.co.com"],
    ["Yezzi", "Min l3b", "ymin@cocococo.com"]
];
var Filters = {
    constituencyID: "",
    pollingDivisionID: "",
    dataForYear: "",
    newVotersInThisYear: "",
    gender: "",
    descreased: "",
    partyName: "",
    employement: "",
    mobile: "",
    gpsLocation: "",
    vehicleBooking: "",
    houses: 0,
    streets: 0,
    ageFrom: 0,
    ageTo: 0,
    limit: 1000,
    page: 0,

}
class VoterDatabase extends Component {
    csvLink = React.createRef()
    constructor() {
        super();
        this.state = {
            modalIsOpen: false,
            modalIsOpenHouse: false,
            role: '1',
            voter_res: [],
            houses_res: [],
            street_res: [],
            constituency_list: [],
            voter_info: [{
                voter_name: '',
                prefered_party: '',
                pollingDivisionName: '',
                street_name: '',
                mobile_no: '',
                gender: '',
                email: '',
                dob: '',
                constituencyName: '',
                address: '',
                apartment: '',
                building_no: ''
            }],
            years: [],
            startYear: "",
            endYear: "",
            selectedContituency: "",
            selected_gender: "",
            selected_deceased: "",
            currentValues: [],
            downloadData: [],
            headers: [],
            employementArray: [],
            is_mobile: "",
            is_gps: "",
            vehicleBooking: "",
            selected_tab: 0,
            age_from: '',
            age_to: '',
            age_from_array: [],
            age_to_array: [],
            editable: false,
            selectedPollingDiv: "",

            voterID: "",
            name: "",
            gender: "",
            party_name: "",
            dob: "",
            building_no: "",
            apartment: "",
            address: "",
            volunteer: "",
            descreased: "",
            email: "",
            mobile_no: "",
            home_phone_no: "",
            employment: "",
            marital_status: 0,
            no_of_voters: 0,
            cab: ""
        };

        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);

        this.openModalHouse = this.openModalHouse.bind(this);
        this.closeModalHouse = this.closeModalHouse.bind(this);
    }

    openModal(voterData) {
        console.log(voterData);
        //voter_info
        var voter = [];
        const details = {
            voter_name: voterData.name,
            prefered_party: voterData.party_name,
            pollingDivisionName: voterData.pollingDivisionID.pollingDivisionName,
            street_name: voterData.street_name,
            mobile_no: voterData.mobile_no,
            gender: voterData.gender,
            email: voterData.email,
            dob: voterData.dob,
            constituencyName: voterData.constituencyID.constituencyName,
            address: voterData.address,
            apartment: voterData.apartment,
            building_no: voterData.building_no
        }
        voter.push(details);
        this.setState({
            modalIsOpen: true, voter_info: voter,
            voterID: voterData._id,
            name: voterData.name,
            gender: voterData.hasOwnProperty('gender') ? voterData.gender : "",
            party_name: voterData.hasOwnProperty('party_name') ? voterData.party_name : "",
            dob: voterData.hasOwnProperty('dob') ? voterData.dob : "",
            building_no: voterData.hasOwnProperty('building_no') ? voterData.building_no : "",
            apartment: voterData.hasOwnProperty('apartment') ? voterData.apartment : "",
            address: voterData.hasOwnProperty('address') ? voterData.address : "",
            volunteer: voterData.hasOwnProperty('volunteer') ? voterData.volunteer : "",
            descreased: voterData.hasOwnProperty('descreased') ? voterData.descreased : "",
            email: voterData.hasOwnProperty('email') ? voterData.email : "",
            mobile_no: voterData.hasOwnProperty('mobile_no') ? voterData.mobile_no : "",
            home_phone_no: voterData.hasOwnProperty('home_phone_no') ? voterData.home_phone_no : "",
            employment: voterData.hasOwnProperty('employment') ? voterData.employment : "",
            marital_status: voterData.hasOwnProperty('marital_status') ? voterData.marital_status : "",
            no_of_voters: voterData.hasOwnProperty('no_of_voters') ? voterData.no_of_voters : "",
            cab: voterData.hasOwnProperty('cab') ? voterData.cab : ""
        });
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHouse(voterData) {
        var voter = [];
        var details = {};
        voterData.data.map((record) => {


            details = {

                voter_name: record.name,
                prefered_party: record.party_name,
                pollingDivisionName: record.pollingDivisionID.pollingDivisionName,
                street_name: record.street_name,
                mobile_no: record.mobile_no,
                gender: record.gender,
                email: record.email,
                dob: record.dob,
                constituencyName: record.constituencyID.constituencyName,
                address: record.address,
                apartment: record.apartment,
                building_no: record.building_no
            }
            voter.push(details);
        })
        this.setState({ modalIsOpenHouse: true, voter_info: voter });
    }

    closeModalHouse() {
        this.setState({ modalIsOpenHouse: false });
    }


    componentWillMount() {

        this.setState({
            role: localStorage.getItem('role')
        })
        console.log(localStorage.getItem('role'))

    }

    async componentDidMount() {
        let years = [2010, 2015, 2019, 2024];
        let ageFrom = [];
        let ageTo = [];
        this.setState({
            role: localStorage.getItem('role')
        })
        const getAllVoters = {
            constituencyID: "",
            pollingDivisionID: "",
            dataForYear: "",
            newVotersInThisYear: "",
            gender: "",
            descreased: "",
            partyName: "",
            employement: "",
            mobile: "",
            gpsLocation: "",
            vehicleBooking: "",
            houses: 0,
            streets: 0,
            selected_tab: 0,
            page: 0,
            limit: 1000,

            currentPage: 1
        }
        await this.props.listConsituency();
        await this.props.getVoters(getAllVoters);
  //await this.props.getPollingDiv();

        let startYear = 2010;
        let startAge = 18;
        let endAge = 18;
        // let currentYear = new Date().getFullYear() + 1;
        // while (startYear <= currentYear) {
        //     years.push(startYear++);
        // }
        // Start Age Array
        while (startAge <= 100) {
            ageFrom.push(startAge++);
        }
        // End Age Array
        while (endAge <= 100) {
            ageTo.push(endAge++);
        }

        this.setState({ years: years, age_from_array: ageFrom, age_to_array: ageTo })
    }
    //Get Voter Result
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.voter_res !== prevState.voter_res) {
            return { voter_res: nextProps.voter_res };
        }
        if (nextProps.houses_res !== prevState.houses_res) {
            return { houses_res: nextProps.houses_res };
        }
        if (nextProps.street_res !== prevState.street_res) {
            return { street_res: nextProps.street_res };
        }

        else return null;
    }

    //On constituency Changed
    ConstituencyChanged = e => {
        if (e.target.value == '') {
            this.setState({ selectedContituency: e.target.value })
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.constituencyID = "";
            this.props.getVoters(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.constituencyID = e.target.value;
            console.log(e.target.value);
            this.setState({ selectedContituency: e.target.value })
            this.props.getPollingDiv(e.target.value);
            this.props.getVoters(Filters);
        }
    };


    //PollingDivChanged
    PollingDivChanged = e => {
        if (e.target.value == '') {
            this.setState({ selectedPollingDiv: e.target.value })
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.pollingDivisionID = "";
            this.props.getVoters(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.pollingDivisionID = e.target.value;
            console.log(e.target.value);
            this.setState({ selectedPollingDiv: e.target.value })
            this.props.getVoters(Filters);
        }
    };



    //On Start Year Changed
    startYearChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ startYear: '' })

            Filters.dataForYear = "";
            this.props.getVoters(Filters);
        } else {

            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            //Filters.dataForYear = e.target.value;
            this.setState({ startYear: parseInt(e.target.value) }, () => {
                if (this.state.endYear != "") {
                    Filters.dataForYear = [this.state.startYear, this.state.endYear]
                }
                else {
                    Filters.dataForYear = [this.state.startYear]
                }
                this.props.getVoters(Filters);
            })
        }
    };

    //On Start Age Changed
    startAgeChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ age_from: 0 })
            Filters.ageFrom = 0;
            Filters.ageTo = 0;
            this.props.getVoters(Filters);
        } else {

            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            var ageTostart = parseInt(e.target.value);
            var updateArray = [];
            while (ageTostart <= 100) {
                updateArray.push(ageTostart++)
            }

            this.setState({ age_from: parseInt(e.target.value), age_to_array: updateArray }, () => {
                if (this.state.age_to != 0) {
                    Filters.ageFrom = this.state.age_from;
                    Filters.ageTo = this.state.age_to;
                    this.props.getVoters(Filters);
                }
            })
        }
    };

    //On End Age Changed
    endAgeChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ age_to: 0 })
            Filters.ageFrom = 0;
            Filters.ageTo = 0;
            this.props.getVoters(Filters);   
        } else {

            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }

            this.setState({ age_to: parseInt(e.target.value) }, () => {
                if (this.state.age_from != 0) {
                    Filters.ageFrom = this.state.age_from;
                    Filters.ageTo = this.state.age_to;
                    this.props.getVoters(Filters);
                }
            })
        }
    };


    //On Start Year Changed
    endYearChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ endYear: '' }, () => {
                if (this.state.startYear != "") {
                    Filters.dataForYear = [this.state.startYear];
                }
                else {
                    Filters.dataForYear = [];
                }
                this.props.getVoters(Filters);
            })

        } else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.dataForYear = [this.state.startYear, parseInt(e.target.value)];
            this.setState({ endYear: parseInt(e.target.value) })
            this.props.getVoters(Filters);
        }
    };

    genderChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ selected_gender: '' })
            Filters.gender = "";
            this.props.getVoters(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.gender = e.target.value;
            this.setState({ selected_gender: e.target.value })
            this.props.getVoters(Filters);
        }
    };
    //Deceased changed
    DeceasedChanged = e => {
        if (e.target.value == '') {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            this.setState({ selected_deceased: '' })
            Filters.descreased = "";
            this.props.getVoters(Filters);
        } else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 1000;
            }
            Filters.descreased = e.target.value;
            this.setState({ selected_deceased: e.target.value })
            this.props.getVoters(Filters);
        }
    };

    //Deceased changed
    mobileChanged = e => {

        if (this.state.selected_tab === 0) {
            Filters.houses = 0;
            Filters.streets = 0;
        }
        if (this.state.selected_tab === 1) {
            Filters.streets = 0;
            Filters.houses = 1;
        }
        if (this.state.selected_tab === 2) {
            Filters.houses = 0;
            Filters.streets = 1;
            Filters.limit = 1000;
        }
        this.setState({ is_mobile: e.target.value })

        Filters.mobile = e.target.value;
        this.props.getVoters(Filters);
    };

    //  //Gps Changes
    gpsChanged = e => {

        if (this.state.selected_tab === 0) {
            Filters.houses = 0;
            Filters.streets = 0;
        }
        if (this.state.selected_tab === 1) {
            Filters.streets = 0;
            Filters.houses = 1;
        }
        if (this.state.selected_tab === 2) {
            Filters.houses = 0;
            Filters.streets = 1;
            Filters.limit = 1000;
        }
        this.setState({ is_gps: e.target.value })
        Filters.gpsLocation = e.target.value;
        this.props.getVoters(Filters);
    };

    //vehicleBooking Changes
    vehicleBookingChanged = e => {

        if (this.state.selected_tab === 0) {
            Filters.houses = 0;
            Filters.streets = 0;
        }
        if (this.state.selected_tab === 1) {
            Filters.streets = 0;
            Filters.houses = 1;
        }
        if (this.state.selected_tab === 2) {
            Filters.houses = 0;
            Filters.streets = 1;
            Filters.limit = 1000;
        }
        this.setState({ vehicleBooking: e.target.value }, () => {
            console.log(this.state.vehicleBooking)
        })
        if (e.target.value == '1') {
            Filters.vehicleBooking = true;
        }
        else if (e.target.value == '0') {
            Filters.vehicleBooking = false;
        }
        else {
            Filters.vehicleBooking = "";
        }

        this.props.getVoters(Filters);
    };
    //Get Tab Value
    getTabVal = (index, label) => {

        this.setState({ selected_tab: index })
        // var getAllVoters={
        //     constituencyID : this.state.selectedContituency,
        //     dataForYear:this.state.startYear,
        //     newVotersInThisYear:"",
        //     gender:this.state.gender,
        //     descreased:"",
        //     partyName:"",
        //     employement:"",
        //     mobile:"",
        //     gpsLocation:"",
        //     vehicleBooking:false,
        //     houses:0,
        //     streets:0
        //     }

        if (index === 0) {
            Filters.houses = 0;
            Filters.streets = 0;
            this.props.getVoters(Filters);
        }
        if (index === 1) {
            Filters.houses = 1;
            Filters.streets = 0;
            Filters.limit = 10000;
            this.props.getVoters(Filters);
        }
        if (index === 2) {
            Filters.houses = 0;
            Filters.streets = 1;
            Filters.limit = 1000;
            this.props.getVoters(Filters);
        }


        console.log(index, label)
    }

    handleChecked = (event) => {

        var test = this.state.currentValues;
        if (event.target.checked === true) {
            test.push(event.target.value)
        }
        else {
            var index = test.indexOf(event.target.value);
            if (index > -1) {
                test.splice(index, 1);
            }
        }
        this.setState({ currentValues: test }, () => {
            console.log(this.state.currentValues)
            if (this.state.currentValues.length > 0) {
                if (this.state.selected_tab === 0) {
                    Filters.houses = 0;
                    Filters.streets = 0;
                }
                if (this.state.selected_tab === 1) {
                    Filters.streets = 0;
                    Filters.houses = 1;
                }
                if (this.state.selected_tab === 2) {
                    Filters.houses = 0;
                    Filters.streets = 1;
                    Filters.limit = 1000;
                }
                Filters.constituencyID = this.state.selectedContituency;
                Filters.pollingDivisionID = this.state.selectedPollingDiv;
                Filters.partyName = this.state.currentValues;
                this.props.getVoters(Filters);

            }
            else {
                if (this.state.selected_tab === 0) {
                    Filters.houses = 0;
                    Filters.streets = 0;
                }
                if (this.state.selected_tab === 1) {
                    Filters.streets = 0;
                    Filters.houses = 1;
                }
                if (this.state.selected_tab === 2) {
                    Filters.houses = 0;
                    Filters.streets = 1;
                    Filters.limit = 1000;
                }
                Filters.constituencyID = this.state.selectedContituency;
                Filters.pollingDivisionID = this.state.selectedPollingDiv;

                this.props.getVoters(Filters);
            }
        })


    }
    //Handle Employeement
    handleEmployement = (event) => {
        var test = this.state.employementArray;
        if (event.target.checked === true) {
            test.push(parseInt(event.target.value))
        }
        else {
            var index =test.indexOf(parseInt(event.target.value));
             if (index > -1) {
                test.splice(index,1);
            }
        }
        this.setState({ employementArray: test }, () => {
            
            if (this.state.employementArray.length > 0) {
                if (this.state.selected_tab === 0) {
                    Filters.houses = 0;
                    Filters.streets = 0;
                }
                if (this.state.selected_tab === 1) {
                    Filters.streets = 0;
                    Filters.houses = 1;
                }
                if (this.state.selected_tab === 2) {
                    Filters.houses = 0;
                    Filters.streets = 1;
                    Filters.limit = 1000;
                }
                Filters.constituencyID = this.state.selectedContituency;
                Filters.pollingDivisionID = this.state.selectedPollingDiv;

                Filters.employement = this.state.employementArray;
                console.log(Filters);
                this.props.getVoters(Filters);

            }
            else {
                if (this.state.selected_tab === 0) {
                    Filters.houses = 0;
                    Filters.streets = 0;
                }
                if (this.state.selected_tab === 1) {
                    Filters.streets = 0;
                    Filters.houses = 1;
                }
                if (this.state.selected_tab === 2) {
                    Filters.houses = 0;
                    Filters.streets = 1;
                    Filters.limit = 1000;
                }
                Filters.constituencyID = this.state.selectedContituency;
                Filters.pollingDivisionID = this.state.selectedPollingDiv;

                this.props.getVoters(Filters);
            }
        })
    }
    download = (event) => {
        var voterDataArray = [];
        var headers = [
            { label: "voterSystemID", key: "voterSystemID" },
            { label: "constituency", key: "constituencyID" },
            { label: "pollingDivision", key: "pollingDivisionID" },
            { label: "Name", key: "name" },
            { label: "Gender", key: "gender" },
            { label: "Email", key: "email" },
            { label: "DOB", key: "dob" },
            { label: "Apartment", key: "apartment" },
            { label: "Building", key: "building_no" },
            { label: "Address", key: "address" },
            { label: "Vehicle Requested", key: "cab" },
            { label: "Mobile", key: "mobile_no" },
            { label: "Party Name", key: "party_name" },
        ];


        if (this.state.voter_res.length > 0) {
            this.state.voter_res.map((voterData) => {
                voterDataArray.push({
                    voterSystemID:voterData.voterSystemID,
                    constituencyID:voterData.constituencyID.constituencyName,
                    pollingDivisionID:voterData.pollingDivisionID.pollingDivisionName,
                    name: voterData.name,
                    gender: voterData.gender,
                    email: voterData.email,
                    dob: voterData.dob,
                    apartment: voterData.apartment,
                    building_no: voterData.building_no,
                    address: voterData.address,
                    cab: voterData.cab,
                    mobile_no: voterData.mobile_no,
                    party_name: voterData.party_name,
                })
            })
        }
        this.setState({ downloadData: voterDataArray, headers: headers }, () => {
            this.csvLink.current.link.click()
        })

    }

    //Reset Button
    reset = async () => {
        this.setState({
            startYear: "",
            endYear: "",
            selectedContituency: "",
            selectedPollingDiv: "",
            pollingDivisionID:"",
            selected_gender: "",
            selected_deceased: "",
            currentValues: [],
            employementArray: [],
            is_mobile: "",
            is_gps: "",
            vehicleBooking: "",
            selected_tab: 0,
            age_from: '',
            age_to: ''

        });


        Filters.constituencyID = "";
        Filters.pollingDivisionID = "";
        Filters.dataForYear = "";
        Filters.newVotersInThisYear = "";
        Filters.gender = "";
        Filters.descreased = "";
        Filters.partyName = "";
        Filters.employement = "";
        Filters.mobile = "";
        Filters.gpsLocation = "";
        Filters.vehicleBooking = "";
        Filters.houses = 0;
        Filters.streets = 0;
        Filters.selected_tab = 0;
        Filters.ageFrom = 0;
        Filters.ageTo = 0;

        await this.props.getVoters(Filters);


    }
    //New Year Switch
    handleNewYear = (e) => {
        console.log(e.target.checked);
        var currentYear = 2024
        if (e.target.checked) {

            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 10000;
            }

            Filters.dataForYear = [currentYear];
            Filters.newVotersInThisYear=1;
            this.props.getVoters(Filters);
        }
        else {
            if (this.state.selected_tab === 0) {
                Filters.houses = 0;
                Filters.streets = 0;
            }
            if (this.state.selected_tab === 1) {
                Filters.streets = 0;
                Filters.houses = 1;
            }
            if (this.state.selected_tab === 2) {
                Filters.houses = 0;
                Filters.streets = 1;
                Filters.limit = 10000;
            }

            Filters.dataForYear = "";
            Filters.newVotersInThisYear=0;
            this.props.getVoters(Filters);
        }
    }



    // handleUpdateVoter() {
    //     this.setState({
    //         editable:!this.state.editable,
    //     });
    //   }

    handleUpdateVoter = () => {
        this.setState(state => ({
            editable: !state.editable
        }));
    };

    UpdateVoter = () => {

        const VoterUpdateData = {
            voterID: this.state.voterID,
            name: this.state.name,
            gender: this.state.gender,
            party_name: this.state.party_name,
            dob: this.state.dob,
            building_no: this.state.building_no,
            apartment: this.state.apartment,
            address: this.state.address,
            volunteer: this.state.volunteer,
            descreased: this.state.descreased,
            email: this.state.email,
            mobile_no: this.state.mobile_no,
            home_phone_no: this.state.home_phone_no,
            employment: this.state.employment,
            marital_status: this.state.marital_status,
            no_of_voters: this.state.no_of_voters,
            cab: this.state.cab
        }
        console.log('Updated Voter')
        console.log(VoterUpdateData)
        this.props.updateVoter(VoterUpdateData);
        this.setState({ modalIsOpen: false });

    }


    onPageNavigate = (next) => {
        console.log('vk')
    }

    render() {
        console.log('Role Value');
        console.log(this.state.employementArray.indexOf(1));

        const header = [
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'Address', prop: 'address', sortable: true },
        ];

        var bodyVoter = [];
        if (this.props.voter_res.length > 0) {
            this.props.voter_res.map((voterData) => {
                bodyVoter.push({
                    name: <button className="btn-text" onClick={() => this.openModal(voterData)}>
                        <p className="font-weight-bold">{voterData.name}</p>
                    </button>,
                    address: <button className="btn-text" onClick={() => this.openModal(voterData)}>
                        <p>{voterData.building_no},{voterData.apartment},{voterData.address}</p>
                    </button>,
                })

            })
        }

        const headerhouse = [
            { title: 'Name', prop: 'name', sortable: true },
        ];
        var bodyHouses = [];
        if (this.props.houses_res.length > 0) {
            this.props.houses_res.map((voterData) => {
                bodyHouses.push({
                    name: <button className="btn-text" onClick={() => this.openModalHouse(voterData)}>
                        <p className="font-weight-bold">{(voterData.data) ? voterData.data[0].apartment : ''},{(voterData.data) ? voterData.data[0].address : ''}</p>
                    </button>,
                })

            })
        }


        const headerStreets = [
            { title: 'Name', prop: 'name', sortable: true },
            { title: 'options', prop: 'options' },

        ];

        var body = [];
        if (this.props.street_res.length > 0) {
            this.props.street_res.map((voterData) => {
                body.push({
                    name: <p className="font-weight-bold">{voterData.data[0].address}</p>,
                    options: <div className="right_side_val">
                        <div className="rvalue bg-secondary">0</div>
                        <div className="rvalue bg-success">2</div>
                        <div className="rvalue bg-primary">1</div>
                        <div className="rvalue bg-danger">0</div>
                    </div>,
                })

            })

            //console.log(body);
        }

        const onSortFunction = {
            date(columnValue) {
                return moment(columnValue, 'Do MMMM YYYY').valueOf();
            },
        };

        const customLabels = {
            first: '<<',
            last: '>>',
            prev: '<',
            next: '>',
            show: 'Display',
            entries: 'rows',
            noResults: 'There is no data to be displayed',
        };

        //console.log('Total Data')
        //console.log(this.props.total_voters,this.props.total_houses,this.props.total_streets)
        return (

            <div>

                <div className="container-fluid">
                    <div className="row my-3 px-4 printRow">
                        <div className="col-sm-4">
                            <h1 className="text-primary ">Voter Database</h1>
                        </div>
                        <div className="col-sm-3 pl-0">
                            <div className="title_right_sec">
                                {/* <CSVLink data={csvData}>Download me</CSVLink>; */}


                                <button className="btn_cust btn-primary pull-left mt-1 printHide" onClick={this.download}>Download Voter list</button>
                                <button className="btn_cust btn-primary pull-left mt-1 ml-2 printHide" onClick={() => window.print()}>Print Voter list</button>
                                <CSVLink
                                    data={this.state.downloadData}
                                    headers={this.state.headers}
                                    filename="data.csv"
                                    className="hidden"
                                    ref={this.csvLink}
                                    target="_blank" />

                            </div>
                        </div>
                    </div>

                    <Modal
                        isOpen={this.state.modalIsOpenHouse}
                        onRequestClose={false}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="modal_head">
                            <h1 className="text-primary ">{(this.state.selected_tab == 1) ? this.state.voter_info[0].building_no : this.state.voter_info[0].street_name}</h1>
                            <button className="btn_close" onClick={this.closeModalHouse}>x</button>
                        </div>

                        <div className="modal_body voterModal">

                            <ul className="list-group list-group-flush listContainer">
                                <div className="card-header font-weight-bold">Voters Information</div>



                                {(this.state.voter_info.length > 0) ?
                                    this.state.voter_info.map((voter_info) => {

                                        return (<li className="list-group-item">
                                            <p className="font-weight-bold">{voter_info.voter_name}</p>
                                            <p>{voter_info.apartment},{voter_info.building_no},{voter_info.address}</p>
                                            <p>{voter_info.gender}</p>
                                            <p>{voter_info.email}</p>
                                            <p>{voter_info.mobile_no}</p>
                                        </li>)
                                    })

                                    : null
                                }


                            </ul>



                        </div>

                    </Modal>


                    <div className="row px-4 mt-5">
                        <div className="col-sm-7">
                            <Tabs onSelect={(index, label) => this.getTabVal(index, label)} selected={this.state.selected_tab}>

                                <Tab label={`Voters(${this.props.total_voters})`}>
                                    <div className="shadow_box mb-5 mt-3 p-0">
                                        <div className="card custom_datatable voter_datatable">
                                            {(this.props.loading) ?

                                                <div class="spinner-border text-primary" role="status">
                                                    <span class="sr-only">Loading...</span>
                                                </div> :
                                                <Datatable
                                                    tableHeader={header}
                                                    tableBody={bodyVoter}
                                                    keyName="userTable"
                                                    tableClass="striped hover responsive"
                                                    rowsPerPage={50}
                                                    rowsPerPageOption={[50, 100, 150, 200]}
                                                    initialSort={{ prop: "username", isAscending: true }}
                                                    onSort={onSortFunction}
                                                    labels={customLabels}
                                                    aync={{
                                                        currentPage: this.state.currentPage,
                                                        onPaginate: (next) => console.log(next)

                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>


                                    <Modal
                                        isOpen={this.state.modalIsOpen}
                                        onRequestClose={false}
                                        style={customStyles}
                                        contentLabel="Example Modal"
                                    >
                                        <div className="modal_head">
                                            <h1 className="text-primary ">{this.state.voter_info[0].voter_name}</h1>
                                            <button className="btn_close" onClick={this.closeModal}>x</button>
                                        </div>

                                        <div className="modal_body voterModal">
                                            <ul className="list-group list-group-flush listContainer">

                                                <li className="list-group-item">
                                                    <div style={{ display: this.state.editable ? 'none' : 'block' }}>
                                                        <p className="font-weight-bold">{this.state.voter_info[0].street_name}</p>
                                                        <p>{this.state.voter_info[0].apartment},{this.state.voter_info[0].building_no},{this.state.voter_info[0].address}</p>
                                                        <p>{this.state.voter_info[0].gender}</p>
                                                        <p>{this.state.voter_info[0].email}</p>
                                                        <p>{this.state.voter_info[0].mobile_no}</p>
                                                        <button className="btn_cust btn-primary mt-3" style={{ position: 'relative', display: 'block', left: 0, }} onClick={() => this.handleUpdateVoter()}>Edit Voter</button>
                                                    </div>

                                                    <div style={{ display: this.state.editable ? 'block' : 'none' }}>
                                                        <div className="mb-3">
                                                            <label className="mr-2"><input type="radio" name="gender" value="MALE" onChange={(e) => this.setState({ gender: e.target.value })} checked={(this.state.gender == 'MALE' ? true : false)} /> Male</label>
                                                            <label className="mr-2"><input type="radio" name="gender" value="FEMALE" onChange={(e) => this.setState({ gender: e.target.value })} checked={(this.state.gender == 'FEMALE' ? true : false)} /> Female</label>
                                                        </div>
                                                        <input type="text" className="form-control mb-3" name="party" placeholder="Party" value={this.state.party_name} onChange={(e) => this.setState({ party_name: e.target.value })} />
                                                        <input type="text" className="form-control mb-3" name="YOB" placeholder="Year of Birth" value={this.state.dob} onChange={(e) => this.setState({ dob: e.target.value })} />
                                                        <div className="mb-3">
                                                            <label className="mr-2"><input type="radio" name="volunteer" value="2" onChange={(e) => this.setState({ volunteer: e.target.value })} checked={(this.state.volunteer == '2' ? true : false)} /> Volunteer</label>
                                                            <label className="mr-2"><input type="radio" name="volunteer" value="1" onChange={(e) => this.setState({ volunteer: e.target.value })} checked={(this.state.volunteer == '1' ? true : false)} /> Non-Volunteer</label>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="mr-2"><input type="radio" name="Deceased" value="1" onChange={(e) => this.setState({ descreased: e.target.value })} checked={(this.state.descreased == '1' ? true : false)} />Alive</label>
                                                            <label className="mr-2"><input type="radio" name="Deceased" value="2" onChange={(e) => this.setState({ descreased: e.target.value })} checked={(this.state.descreased == '2' ? true : false)} /> Deceased</label>
                                                        </div>
                                                        <input type="text" className="form-control mb-3" name="email" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                        <div className="mb-3">
                                                            <label className="mr-2">Do you want Cab?</label>
                                                            <label className="mr-2"><input type="radio" name="cab" value="Yes" onChange={(e) => this.setState({ cab: e.target.value })} />Yes</label>
                                                            <label className="mr-2"><input type="radio" name="cab" value="No" onChange={(e) => this.setState({ cab: e.target.value })} /> NO</label>
                                                        </div>
                                                        <input type="text" className="form-control mb-3" name="mobile_number" placeholder="Mobile" value={this.state.mobile_no} onChange={(e) => this.setState({ mobile_no: e.target.value })} />
                                                        <input type="text" className="form-control mb-3" name="home_number" placeholder="Home Phone" value={this.state.home_phone_no} onChange={(e) => this.setState({ home_phone_no: e.target.value })} />

                                                        <div className="mb-3">
                                                        <label><input type="radio" name="employementStatus" value="1" onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '1' ? true : false)}/> Employed</label>
                                                        <label><input type="radio" name="employementStatus" value="2" onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '2' ? true : false)}/> Unemployed</label>
                                                        <label><input type="radio" name="employementStatus" value="3" onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '3' ? true : false)}/> Self Employed</label>
                                                        <label><input type="radio" name="employementStatus" value="4" onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '4' ? true : false)}/> Retired</label>
                                                        <label><input type="radio" name="employementStatus" value="5"  onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '5' ? true : false)}/> Fulltime Student</label>
                                                        <label><input type="radio" name="employementStatus" value="6" onChange={(e)=>this.setState({employment:e.target.value})} checked={(this.state.employment == '6' ? true : false)}/> Unspecified</label>
                                                    

                                                        </div>

                                                        <div className="mb-3">
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="1" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '1' ? true : false)} />Single </label>
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="2" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '2' ? true : false)} /> Married</label>
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="3" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '3' ? true : false)} /> Divorced</label>
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="4" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '4' ? true : false)} />  Widowed</label>
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="5" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '5' ? true : false)} /> Other</label>
                                                            <label className="mr-2"><input type="radio" name="maritalStatus" value="6" onChange={(e) => this.setState({ marital_status: e.target.value })} checked={(this.state.marital_status == '6' ? true : false)} /> Prefer not to say</label>
                                                        </div>
                                                        <input type="text" className="form-control mb-3" name="voter_number" placeholder="No of voter in their house" value={this.state.no_of_voters} onChange={(e) => this.setState({ no_of_voters: e.target.value })} />

                                                        <button className="btn btn-sm btn-success" onClick={() => this.UpdateVoter()}>Update</button>
                                                    </div>

                                                </li>

                                            </ul>

                                        </div>

                                    </Modal>
                                </Tab>

                                <Tab label={`Houses(${this.props.total_houses})`}>

                                    <div className="shadow_box mb-5 mt-3 p-0">
                                        <div className="card">
                                            <div className="card custom_datatable voter_datatable">
                                                {(this.props.loading) ?

                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> :
                                                    <Datatable
                                                        tableHeader={headerhouse}
                                                        tableBody={bodyHouses}
                                                        keyName="userTable"
                                                        tableClass="striped hover responsive"
                                                        rowsPerPage={50}
                                                        rowsPerPageOption={[50, 100, 150, 200]}
                                                        initialSort={{ prop: "username", isAscending: true }}
                                                        onSort={onSortFunction}
                                                        labels={customLabels}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </Tab>


                                <Tab label={`Streets(${this.props.total_streets})`}>

                                    <div className="shadow_box mb-5 mt-3 p-0">
                                        <div className="card">
                                            <div className="card custom_datatable voter_datatable">
                                                {(this.props.loading) ?

                                                    <div class="spinner-border text-primary" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div> :
                                                    <Datatable
                                                        tableHeader={headerStreets}
                                                        tableBody={body}
                                                        keyName="userTable"
                                                        tableClass="striped hover responsive"
                                                        rowsPerPage={50}
                                                        rowsPerPageOption={[50, 100, 150, 200]}
                                                        initialSort={{ prop: "username", isAscending: true }}
                                                        onSort={onSortFunction}
                                                        labels={customLabels}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>




                                </Tab>
                            </Tabs>
                        </div>


                        <div className="col-sm-5">
                            <div className="Voterfilter">
                                <div className="VoterFilterHead">Filters</div>
                                <ul className="list-group list-group-flush listContainer">
                                    <div className="card-header font-weight-bold">VOTER
                            <button className="btn_cust btn-info pull-right" onClick={this.reset}>Reset</button></div>

                                    {this.state.role === '1' ?
                                        <li className="list-group-item">
                                            <p>Select Constituency</p>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-control"
                                                        id="const_sele"
                                                        name="consti"
                                                        onChange={this.ConstituencyChanged}
                                                        value={this.state.selectedContituency}
                                                    >
                                                        <option value="">Select a Constituency</option>
                                                        {this.props.constituency_list.map((data, index) => {
                                                            return (
                                                                <option value={data._id}>
                                                                    {data.constituencyName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </li>
                                        : null}

                                    {this.state.role === '1' ?
                                        <li className="list-group-item">
                                            <p>Select Polling Station</p>
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <select
                                                        className="form-control"
                                                        id="const_sele"
                                                        name="consti"
                                                        onChange={this.PollingDivChanged}
                                                        value={this.state.selectedPollingDiv}
                                                    >
                                                        <option value="">Select a Polling Station</option>
                                                        {this.props.polling_div.map((data, index) => {
                                                            return (
                                                                <option value={data._id}>
                                                                    {data.pollingDivisionName}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        </li> : null}

                                    <li className="list-group-item">
                                        <p>Compare Data for Year</p>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <select className="form-control" onChange={this.startYearChanged}
                                                    value={this.state.startYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    {(this.state.years.length > 0) ?
                                                        this.state.years.map((year) => {
                                                            return (<option >{year}</option>)
                                                        })
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="col-sm-6">
                                                <select className="form-control" onChange={this.endYearChanged}
                                                    value={this.state.endYear}
                                                >
                                                    <option value="">Select Year</option>
                                                    {(this.state.years.length > 0) ?
                                                        this.state.years.map((year) => {
                                                            return (<option >{year}</option>)
                                                        })
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                    </li>

                                    <li className="list-group-item">
                                        <p>New Voters in this year</p>

                                        <div className="pull-right" style={{ position: 'absolute', right: 15, top: 15, }}>
                                            <Toggle
                                                defaultChecked={this.state.tofuIsReady}
                                                icons={false}
                                                onChange={(e) => this.handleNewYear(e)} style={{ marginTop: '5px', }} />
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Age</p>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <label>From</label>
                                                <select className="form-control" onChange={this.startAgeChanged}
                                                    value={this.state.age_from}>
                                                    <option value="">From Age</option>
                                                    {(this.state.age_from_array.length > 0) ?
                                                        this.state.age_from_array.map((age) => {
                                                            return (<option >{age}</option>)
                                                        })
                                                        : null}
                                                </select>
                                            </div>
                                            <div className="col-sm-6">
                                                <label>To</label>
                                                <select className="form-control" onChange={this.endAgeChanged}
                                                    value={this.state.age_to}>
                                                    <option value="">To Age</option>
                                                    {(this.state.age_to_array.length > 0) ?
                                                        this.state.age_to_array.map((age) => {
                                                            return (<option >{age}</option>)
                                                        })
                                                        : null}
                                                </select>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Gender</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="radio" name="gender" value="MALE" onChange={this.genderChanged} checked={(this.state.selected_gender == 'MALE' ? true : false)} /> Male</label>
                                                <label><input type="radio" name="gender" value="FEMALE" onChange={this.genderChanged} checked={(this.state.selected_gender == 'FEMALE' ? true : false)} /> Female</label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Deceased</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="radio" name="deceased" value="1" onChange={this.DeceasedChanged} checked={(this.state.selected_deceased == '1' ? true : false)} /> Yes</label>
                                                <label><input type="radio" name="deceased" value="2" onChange={this.DeceasedChanged} checked={(this.state.selected_deceased == '2' ? true : false)} /> No</label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Party</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="checkbox" name="party" value="PNM" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('PNM') > -1)?true:false} />PNM</label>
                                                <label><input type="checkbox" name="party" value="YEP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('YEP') > -1)?true:false} /> YEP</label>
                                                <label><input type="checkbox" name="party" value="ILP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('ILP') > -1)?true:false} />ILP</label>
                                                <label><input type="checkbox" name="party" value="CA" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('CA') > -1)?true:false} />CA</label>
                                                <label><input type="checkbox" name="party" value="TPT" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('TPT') > -1)?true:false} />TPT</label>
                                                <label><input type="checkbox" name="party" value="DDP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('DDP') > -1)?true:false} />DDP</label>
                                                <label><input type="checkbox" name="party" value="COP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('COP') > -1)?true:false} /> COP</label>
                                            
                                                <label><input type="checkbox" name="party" value="NNV" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('NNV') > -1)?true:false} />NNV</label>
                                                <label><input type="checkbox" name="party" value="DNA" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('DNA') > -1)?true:false} /> DNA</label>
        
                                                <label><input type="checkbox" name="party" value="TOP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('TOP') > -1)?true:false} />TOP</label>
                                                <label><input type="checkbox" name="party" value="NCT" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('NCT') > -1)?true:false} />NCT</label>
                                                <label><input type="checkbox" name="party" value="TNV" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('TNV') > -1)?true:false} /> TNV</label>
       
                                            
                                                <label><input type="checkbox" name="party" value="TTNCP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('TTNCP') > -1)?true:false} />TTNCP</label>
                                                <label><input type="checkbox" name="party" value="DAC" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('DAC') > -1)?true:false} /> DAC</label>
                                                <label><input type="checkbox" name="party" value="UNC" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('UNC') > -1)?true:false} />UNC</label>
                                                <label><input type="checkbox" name="party" value="TF" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('TF') > -1)?true:false} />TF</label>
                                                <label><input type="checkbox" name="party" value="THC" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('THC') > -1)?true:false} />THC</label>

                                                <label><input type="checkbox" name="party" value="IND" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('IND') > -1)?true:false} />IND</label>
                                                <label><input type="checkbox" name="party" value="LOVE" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('LOVE') > -1)?true:false} />LOVE</label>
                                                <label><input type="checkbox" name="party" value="IDP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('IDP') > -1)?true:false} />IDP</label>
                                                <label><input type="checkbox" name="party" value="MSJ" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('MSJ') > -1)?true:false} />MSJ</label>
                                                <label><input type="checkbox" name="party" value="PDP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('PDP') > -1)?true:false} />PDP</label>
                                           
                                                <label><input type="checkbox" name="party" value="DPTT" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('DPTT') > -1)?true:false} />DPTT</label>
                                                <label><input type="checkbox" name="party" value="NAR" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('NAR') > -1)?true:false} />NAR</label>
                                                <label><input type="checkbox" name="party" value="YOUR" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('YOUR') > -1)?true:false} />YOUR</label>
                                                <label><input type="checkbox" name="party" value="NJAC" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('NJAC') > -1)?true:false} />NJAC</label>
                                                <label><input type="checkbox" name="party" value="PP" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('PP') > -1)?true:false} />PP</label>
                                                <label><input type="checkbox" name="party" value="PF" onClick={this.handleChecked} checked={(this.state.currentValues.indexOf('PF') > -1)?true:false} />PF</label>

                                           
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Employement</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="checkbox" name="employement" value="1" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(1) > -1)?true : false} /> Employed</label>
                                                <label><input type="checkbox" name="employement" value="2" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(2) > -1)?true : false} /> Unemployed</label>
                                                <label><input type="checkbox" name="employement" value="3" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(3) > -1)?true : false} /> Self Employed</label>
                                                <label><input type="checkbox" name="employement" value="4" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(4) > -1)?true : false} /> Retired</label>
                                                <label><input type="checkbox" name="employement" value="5" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(5) > -1)?true : false} /> Full Time Student</label>
                                                <label><input type="checkbox" name="employement" value="6" onClick={this.handleEmployement} checked={(this.state.employementArray.indexOf(6) > -1)?true : false} /> Unspecified</label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Mobile</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="radio" name="mobile" value={"1"} onClick={this.mobileChanged} checked={(this.state.is_mobile == '1' ? true : false)} /> Yes</label>
                                                <label><input type="radio" name="mobile" value={"0"} onClick={this.mobileChanged} checked={(this.state.is_mobile == '0' ? true : false)} /> No</label>
                                            </div>
                                        </div>
                                    </li>


                                    <li className="list-group-item">
                                        <p>GPS Location</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="radio" name="location" value="1" onClick={this.gpsChanged} checked={(this.state.is_gps == '1' ? true : false)} /> Yes</label>
                                                <label><input type="radio" name="location" value="0" onClick={this.gpsChanged} checked={(this.state.is_gps == '0' ? true : false)} /> No</label>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item">
                                        <p>Transportation Required</p>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <label><input type="radio" name="vehicle" value="1" onClick={this.vehicleBookingChanged} checked={(this.state.vehicleBooking === '1' ? true : false)} /> Yes</label>
                                                <label><input type="radio" name="vehicle" value="0" onClick={this.vehicleBookingChanged} checked={(this.state.vehicleBooking === '0' ? true : false)} /> No</label>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>



                </div>


            </div>
        )
    }
}
const mapStateToProps = ({ voter, campTeam }) => {
    const { voter_res, houses_res, street_res, total_voters, total_houses, total_streets, loading } = voter;
    const { constituency_list, polling_div } = campTeam;
    return { voter_res, constituency_list, polling_div, houses_res, street_res, total_voters, total_houses, total_streets, loading };
};
export default connect(
    mapStateToProps,
    actions
)(VoterDatabase);
